import React, { Component } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';

class Foot extends Component {
  render() {
    return (
      <BottomNavigation >
        Jesse Michael
        </BottomNavigation>
      // <footer className="mdl-mini-footer">
      //   <div className="mdl-mini-footer__left-section">
      //       <div className="mdl-logo">Jesse Michael</div>
      //   </div>
      //   <div className="mdl-mini-footer__right-section">
      //     <ul className="mdl-mini-footer__link-list">
      //       <li><a href="#">Help</a></li>
      //     </ul>
      //   </div>
      // </footer>
    )
  }
}

export default Foot;
